import type { StatusColor } from '~/types'

enum ConsultationStatus {
  AwaitingPayment = '1',
  Booked = '2',
  Confirmed = '3',
  Completed = '4',
  Canceled = '5'
}

const { AwaitingPayment, Booked, Confirmed, Completed, Canceled } = ConsultationStatus

const colorMapping: Map<ConsultationStatus, StatusColor> = new Map([
  [AwaitingPayment, 'yellow'],
  [Booked, 'green'],
  [Confirmed, 'orange'],
  [Completed, 'blue'],
  [Canceled, 'gray']
])

const nameMapping = new Map([
  [AwaitingPayment, 'Ожидает оплаты'],
  [Booked, 'Забронирована'],
  [Confirmed, 'Подтверждена'],
  [Completed, 'Проведена'],
  [Canceled, 'Отменена']
])

namespace ConsultationStatus {
  export const sortedMembers = [AwaitingPayment, Booked, Confirmed, Completed, Canceled]

  export function getColor (consultationStatus: ConsultationStatus): StatusColor {
    return colorMapping.get(consultationStatus)!
  }

  export function getName (consultationStatus: ConsultationStatus): string {
    return nameMapping.get(consultationStatus)!
  }
}

export default ConsultationStatus
